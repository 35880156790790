import { useState } from "react";
import { Document, Page } from "react-pdf";
import { Section } from "../Section/Section";
import moment from "moment";

interface PdfSectionProps {
  pdfFile?: string;
  style?: React.CSSProperties;
  subText?: string;
  dateUpdated: Date;
}

const PdfSection = ({ pdfFile, style, subText, dateUpdated }: PdfSectionProps) => {
  const regexPattern: RegExp = /\/files\/([^\/]+)\.pdf/;
  const matchResult = pdfFile?.match(regexPattern) ?? false;

  if (matchResult === false) {
    console.error(
      "The file path does not match the expected pattern, is null or undefined."
    );
    throw new Error(
      "The file path does not match the expected pattern, is null or undefined."
    );
  }

  return (
    <Section style={{ textAlign: "center" }}>
      <h3>
        <small className="text-body-secondary">{subText}</small>
      </h3>
      <p className="h6">Last Updated: {moment(dateUpdated).format("LLL")}</p>

      <iframe src={pdfFile} width={"100%"} height={1000} />
    </Section>
  );
};

export { PdfSection };
