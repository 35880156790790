export interface MyWorkData {
  name: string;
  image: string;
  description: string;
  url?: string | null;
}

export const data: MyWorkData[] = [
  {
    name: "Portfolio",
    image: "/images/portfolio.png",
    description:
      "This portfolio was built from scratch using React and Typescript and hosted on Vercel. I used node modules such as React Bootstrap and moment to help construct the site with the overall design being in my hands.",
  },
  {
    name: "Ducks and Gunz",
    image: "/images/dwg.png",
    url: "https://duckswithgunz.itch.io/ducks-and-gunz",
    description:
      'During my final year of University I led a team of follow students to build a VR experience based around an "arcade" game theme. This project used the Unity Game Engine as a basis and whilst the final product was hampered by the inital COVID lockdown within the UK I am still proud we managed to get the game to a somewhat playable state.',
  },
  {
    name: "Gladiator Game",
    image: "/images/gladiator.png",
    url: "https://dogelixgames.itch.io/gladiator-game",
    description:
      "Developed as part of a team for the Derby Computing Society's Feburary 24hr Game Jam. This jam had the theme of History and the idea behind the game was for you to be a gladiator fighting waves of enemies." 
  },
  {
    name: "Dissertation: Game Production Life Cycle - AR God Game",
    image: "/images/dissertation.png",
    url: "https://drive.google.com/file/d/1gka85EUKarfCi7JsPn2L1m1Bh4r-Ct_u/view?usp=sharing",
    description:
      "This project explores the game production life cycle for a novel augmented reality mobile game. The project only reached to a level of the minimum viable product however, learnings can be taken from this project along with a robust design. The augmented reality god game genre is novel and can be taken, tested and expanded to create a potentially popular mobile game which could be a large undertaking in the future." 
  },
  {
    name: "Datashot FPS",
    image: "/images/datashot.png",
    url: "https://derbyus-compsoc.itch.io/datashot-fps",
    description:
      "Developed in my second year of University with four of my fellow students this as our first major teamwork focused project. This game has A LOT of programmer art but the core concepts were there. The gameplay could use some work but for a first attempt at a game in a team I feel happy to say I worked on this game." 
  },
];
