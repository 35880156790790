import HeadingSection from "../../../components/HeadingSection/HeadingSection";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Section } from "../../../components/Section/Section";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faGraduationCap,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

const Timeline = () => {
  return (
    <>
      <HeadingSection title={"My Career"} />
      <Section
        style={{ backgroundColor: 'linear-gradient("#ffffff", "#f5f5f5")' }}
      >
        <VerticalTimeline lineColor={"#cfcdca"}>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "rgb(103, 182, 245)" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(103, 182, 245)" }}
            date="Nov 2023 – Present"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">
              Senior Software Engineer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              ITHealth U.K. Ltd, Nottingham, UK
            </h4>
            <p>
              Skills: ASP.NET Web API · Microsoft Azure · SQL · Version Control
              · REST APIs · Healthcare · Entity Framework (EF) Core · Healthcare
              Information Technology (HIT)
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Mar 2023 – Nov 2023"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">
              Software Engineer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Tickd, Birmingham, UK
            </h4>
            <p>
              Skills: Software as a Service (SaaS) · Amazon Web Services (AWS) ·
              ASP.NET Web API · SQL · Version Control · REST APIs · Entity
              Framework (EF) Core
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Oct 2022 – Mar 2023"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">
              Full Stack Developer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              DIGI-Steel, Nottingham, UK
            </h4>
            <p>
              Skills: Windows Presentation Framework (WPF) · Amazon Web Service
              (AWS) · Amazon Neptune · C# · ASP.NET Core 6 · CI/CD ·
              Microservice Architecture
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="May 2022 – Oct 2022"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">
              Software Engineer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Oakbrook Finance, Nottingham, UK
            </h4>
            <p>
              Skills: Google Cloud Platform (GCP) · MongoDB, Octopus Deploy · C#
              · ASP.NET Core 6 · React · JS · CI/CD · Microservice Architecture
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Sep 2021 – May 2022"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">
              Software Developer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              STERIS, Derby, UK
            </h4>
            <p>
              Skills: ASP.NET Web API · Microsoft Azure · SQL · Version Control
              · REST APIs · Healthcare · Entity Framework (EF) Core · Healthcare
              Information Technology (HIT)
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Jun 2020 – Sep 2021"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">
              Software Developer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              RDS Global Ltd, Derby, UK
            </h4>
            <p>
              Skills: Software as a Service (SaaS) · ASP.NET Web API · SQL ·
              Version Control · REST APIs · Entity Framework (EF) Core
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Jul 2018 – May 2020"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">
              Junior Software Developer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              RDS Global Ltd, Derby, UK
            </h4>
            <p>
              Skills: Software as a Service (SaaS) · ASP.NET Web API · SQL ·
              Version Control · REST APIs · Entity Framework (EF) Core
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2016-2021"
            iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
            icon={<FontAwesomeIcon icon={faGraduationCap} />}
          >
            <h3 className="vertical-timeline-element-title">
              BSc(Hons) Computer Games and Programming (with Industrial
              Placement)
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              University of Derby
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
            icon={<FontAwesomeIcon icon={faStar} />}
          />
        </VerticalTimeline>
      </Section>
    </>
  );
};

export default Timeline;
