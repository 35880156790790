import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Section } from "../../components/Section/Section";
import { data, MyWorkData } from "./data";
import LoopingVideoHeader from "../../components/LoopingVideoHeader/LoopingVideoHeader";
import Parser from "html-react-parser";

const MyWork = () => {
  const display = data.map((obj: MyWorkData, idx: number) => (
    <Col xs={12} md={4} lg={3} key={idx}>
      <Card style={{ minWidth: "18rem", height: "98%", marginBottom: 10}}>
        <Card.Img variant="top" src={obj.image} height={200} />
        <Card.Body>
          <Card.Title>{obj.name}</Card.Title>
          <Card.Text>{Parser(obj.description)}</Card.Text>
          {obj.url === null || obj.url === undefined ? (
            <></>
          ) : (
            <>
              <Button variant="primary" href={obj.url} target="_blank">
                View
              </Button>
            </>
          )}
        </Card.Body>
      </Card>
    </Col>
  ));

  return (
    <>
      <LoopingVideoHeader
        videoUrl="/videos/BlurredCode.mp4"
        headerText="Jake Yeatman"
        subHeaderText="My Work"
      />
      <Section style={{ marginTop: 5, marginBottom: 10 }}>
        <Container fluid>
          <Row>{display}</Row>
        </Container>
      </Section>
    </>
  );
};

export default MyWork;
