import { Container } from "react-bootstrap";


const Footer = () => {
    const currentYear = (new Date()).getFullYear();
    return(
        <footer className="flex-shrink-0 py-4 bg-dark text-white-50">
            <Container className="text-center">
                <small>Copyright &copy; Jake Yeatman {currentYear}</small>
            </Container>
        </footer>
    );
}

export default Footer;