import { Container } from "react-bootstrap";
import '../LoopingVideoHeader/LoopingVideoHeader.css';

interface LoopingVideoHeaderProps {
  videoUrl: string;
  videoType?: "video/mp4" | string;
  headerText: string;
  subHeaderText: string;
}

const LoopingVideoHeader = ({
  videoUrl,
  videoType = "video/mp4",
  headerText,
  subHeaderText,
}: LoopingVideoHeaderProps) => {
  return (
    <header>
        <div className="overlay"></div>

        <video playsInline={true} autoPlay={true} muted={true} loop={true}>
            <source src={videoUrl} type={videoType} />
        </video>

        <Container className="h-100">
            <div className="d-flex h-100 text-center align-items-center">
            <div className="w-100 text-white">
                <h1 className="display-3">{headerText}</h1>
                <p className="lead mb-0">{subHeaderText}</p>
            </div>
            </div>
        </Container>
    </header>
  );
};

export default LoopingVideoHeader;
