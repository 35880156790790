import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { pdfjs } from "react-pdf";

import "bootstrap/dist/css/bootstrap.min.css";

import NavbarCustom from "./components/Navbar/Navbar";
import Overview from "./screens/Overview/Overview";
import MyWork from "./screens/MyWork/MyWork";
import Footer from "./components/Footer/Footer";

function App() {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.js",
    import.meta.url
  ).toString();

  return (
    <>
      <Router>
        <NavbarCustom />
        <Routes>
          <Route path="/" element={<Overview />} />
          <Route path="/my-work" element={<MyWork />} />
        </Routes>
      </Router>

      <Footer />
    </>
  );
}

export default App;
