import { Container } from "react-bootstrap";
import { SectionSmall } from "../Section/Section";

interface HeadingSectionProps {
  title: string;
}

const HeadingSection = ({ title }: HeadingSectionProps) => {
  return (
    <SectionSmall>
      <Container className="h-100">
        <div className="d-flex h-100 text-center align-items-center">
          <div className="w-100">
            <h2 className="display-3">{title}</h2>
          </div>
        </div>
      </Container>
    </SectionSmall>
  );
};

export default HeadingSection;
