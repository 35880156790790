import { Container } from "react-bootstrap";
import "../Section/Section.css";

export interface SectionProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const Section = ({ style, children }: SectionProps) => {
  return (
    <div className="section" style={style}>
      <Container>{children}</Container>
    </div>
  );
};

const DividerSection = () => {
  return (
    <div className="section-small">
      <hr
        style={{
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 50,
        }}
      />
    </div>
  );
};

const SectionSmall = ({ style, children }: SectionProps) => {
  return (
    <div className="section-small" style={style}>
      <Container>{children}</Container>
    </div>
  );
};

export { Section, SectionSmall, DividerSection };
