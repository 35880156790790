import LoopingVideoHeader from "../../components/LoopingVideoHeader/LoopingVideoHeader";
import "react-vertical-timeline-component/style.min.css";
import "../Overview/Overview.css";
import Timeline from "./components/Timeline";
import HeadingSection from "../../components/HeadingSection/HeadingSection";
import { DividerSection, Section, SectionSmall } from "../../components/Section/Section";
import { PdfSection } from "../../components/PdfSection/PdfSection";

// TODO: Replace video with custom one.

const Overview = () => {
  return (
    <>
      <LoopingVideoHeader
        videoUrl="/videos/BlurredCode.mp4"
        headerText="Jake Yeatman"
        subHeaderText="Welcome"
      />
      <SectionSmall> </SectionSmall>
      <Section>
        <p>
          I'm a proactive and enthusiastic individual eager to tackle any
          project or task, aiming to elevate it to new heights. Whether
          collaborating within a team or working independently, I thrive on
          solving a diverse range of challenges while continuously honing my
          skills and professionalism. I'm committed to gaining extensive
          experience, particularly in enhancing teamwork dynamics and exploring
          diverse programming techniques, styles, and workflows. My background
          in both employment and education has instilled in me a strong work
          ethic, efficient time management, refined interpersonal skills, and
          effective working habits. Beyond my professional pursuits, I hold a
          profound interest in both physical and digital games, as well as
          software development at large.
        </p>
      </Section>
      <Timeline />
      <DividerSection />
      <HeadingSection title={"My CV"} />
      <PdfSection
        pdfFile="/files/Resume-Jake-Yeatman.pdf"
        subText="Feel free to review my CV and contact me if I meet criteria you are looking for."
        dateUpdated={new Date(2024, 5, 3, 22, 18)}
      />
    </>
  );
};

export default Overview;
